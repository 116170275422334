import { groq } from 'next-sanity'
import { z } from 'zod'

import { LocationFields, LocationSchemaDetailed } from '../common/locationData'
import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'

export const dzGalleryHeroFields = groq`
  "_type": "dzGalleryHero",
  'props': {
    title,
    subtitle,
    media { ${mediaBuilder} },
    location->{
      ${LocationFields}
    },
  }
`

export const dzGalleryHeroProps = groq`
  _type == 'dzGalleryHero' => {
    ${dzGalleryHeroFields}
  },
`

export const DzGalleryHeroPropsDataSchema = z.object({
  title: z.nullable(z.string()),
  subtitle: z.nullable(z.string()),
  location: LocationSchemaDetailed,
  media: z.nullable(MediaBuilderSchema),
})

export type DzGalleryHeroPropsDataType = z.infer<typeof DzGalleryHeroPropsDataSchema>
