import { groq } from 'next-sanity'
import { z } from 'zod'

import { componentTypesData, ComponentTypesDataSchema } from './componentTypesData'
import { artworkFilterFields, bookFilterFields, DisplayFiltersSchema } from './displayFilters'
import { dzCardProps, DzCardPropsDataSchema, DzCustomCardPropsSchema } from './dzCardProps'
import { dzMediaProps, DzMediaPropsDataSchema } from './dzMediaProps'

// Must follow DzCarouselSchemaProps
export const dzCarouselFields = groq`
  "_type": "dzCarousel",
  'props': {
    title,
    size,
    artworkFilters{${artworkFilterFields}},
    bookFilters{${bookFilterFields}},
    dzCarousel[]{
      ${componentTypesData}
      ${dzMediaProps}
      ${dzCardProps}
    },
  }
`
export const dzCarouselProps = groq`
  _type == 'dzCarousel' => {
    ${dzCarouselFields}
  },
`

export const getDzCarouselFieldsSimplified = (content: string) => groq`
  "_type": "dzCarousel",
  'props': {
    title,
    size,
    artworkFilters{${artworkFilterFields}},
    bookFilters{${bookFilterFields}},
    dzCarousel[]{
      ${dzMediaProps}
      ${dzCardProps}
      ${content}
    },
  }
`

const DzCarouselItemTypeSchema = z.enum(['dzCard', 'dzMedia'])
// props: DzCarouselPropsDataSchema
export const DzCarouselPropsDataSchema = z
  .object({
    title: z.nullable(z.string()),
    size: z.enum(['XL', 'L', 'M', 'S']).nullish(),
    dzCarousel: z.nullable(
      z.array(
        z.object({
          _type: DzCarouselItemTypeSchema,
          content: ComponentTypesDataSchema,
          props: z.discriminatedUnion('discriminator', [
            DzCardPropsDataSchema,
            DzCustomCardPropsSchema,
            DzMediaPropsDataSchema,
          ]),
        })
      )
    ),
  })
  .merge(DisplayFiltersSchema)

export type DzCarouselPropsDataType = z.infer<typeof DzCarouselPropsDataSchema>
