import { groq } from 'next-sanity'
import { z } from 'zod'

import { artworkContent } from '@/sanity/queries/components/content/artworkContent'
import {
  dzInterstitialFields,
  DzInterstitialPropsDataSchema,
} from '@/sanity/queries/components/dzInterstitialProps'

import { mediaBuilder } from '../builders/mediaBuilder'
import { articleRTEContent, ArticleRTEContentSchema } from '../portableText/articleContent'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import { pageSEOFields, PageSEOFieldsExtendedSchema } from '../seo/pageSEOFields'
import { SanitySlugSchema } from '../validationPrimitives'

const articleContentFields = groq`
  category,
  title,
  type == 'externalNews' || type == 'pressRelease' => {
    image {
      image {
        ...
      }
    },
  },
  type == 'internalNews'=> {
    header[]{
      _type == 'headerImage' => media {
        "caption": ^.caption,
        ${mediaBuilder}
      },
      _type == 'artwork' => @-> {
        _id,
        ${artworkContent}
      },
    },
  },
  description[] {
    ${basicContent}
  },
  location->,
  subtitle,
  slug,
  externalURL,
  type,
  publishDate,
  displayDate,
  primarySubtitle,
  _type,
`

export const articleContent = groq`
  _type == 'article' => {
    ${articleContentFields}
  },
`

export const articleContentShared = groq`
  _type == 'article' => {
    ${articleContentFields}
    body[] {
      ${articleRTEContent}
    },
    seo {
      ${pageSEOFields}
    },
    interstitial {
      ${dzInterstitialFields}
    },
    articles[]->,
    pdf,
  },
`

const ArticleCategorySchema = z.enum([
  'Press',
  'News',
  'Event',
  'Museum Exhibition',
  'Museum Highlights',
])
const ArticleTypeSchema = z.enum(['internalNews', 'pressRelease', 'externalNews'])
// TODO: define type instead any
export const ArticleContentSchema = z.object({
  category: z.nullable(ArticleCategorySchema),
  title: z.string(),
  image: z.any().nullish(),
  header: z.array(z.any()).nullish(),
  description: z.nullable(z.array(BasicContentSchema)),
  location: z.nullable(z.any()),
  slug: z.nullable(SanitySlugSchema),
  externalURL: z.nullable(z.any()),
  type: ArticleTypeSchema,
  publishDate: z.nullable(z.any()),
  displayDate: z.nullable(z.string()),
  subtitle: z.nullable(z.string()),
  primarySubtitle: z.nullable(z.string()),
  _type: z.literal('article'),
})

export type ArticleContentSchemaType = z.infer<typeof ArticleContentSchema>

export const ArticleContentSharedSchema = ArticleContentSchema.merge(
  z.object({
    seo: z.nullable(PageSEOFieldsExtendedSchema),
    body: z.nullable(z.array(ArticleRTEContentSchema)),
    articles: z.nullable(z.array(z.any())),
    interstitial: z.nullable(
      z.object({
        _type: z.literal('dzInterstitial'),
        props: DzInterstitialPropsDataSchema,
      })
    ),
    pdf: z.nullable(z.any()),
  })
)
