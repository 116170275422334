import { groq } from 'next-sanity'
import { z } from 'zod'

export const publisher = groq`
    name,
`

export const bookDataByName = groq`
*[_type == "publisher" && defined(name) && name== $name][0]{
  ${publisher}
}`

export const PublisherDataSchema = z.object({
  name: z.string(),
})

export type PublisherDataType = z.infer<typeof PublisherDataSchema>
