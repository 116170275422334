import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import { SanitySlugSchema } from '../validationPrimitives'

export const onlineExhibitionSimpleFields = groq`
  _id,
  _type,
  title,
  subtitle,
  slug,
  summary[] {
    ${basicContent}
  },
  eyebrow,
  status,
  displayDate,
  startDate,
  endDate,
  hideToggle,
`

const OnlineExhibitionsStatusSchema = z.enum(['comingSoon', 'open', 'close'])

export const OnlineExhibitionSimpleFieldsSchema = z.object({
  _id: z.string(),
  _type: z.literal('onlineExhibitionPage'),
  title: z.string(),
  slug: SanitySlugSchema,
  subtitle: z.nullable(z.string()),
  summary: z.nullable(z.array(BasicContentSchema)),
  startDate: z.string(),
  endDate: z.string(),
  displayDate: z.nullable(z.string()),
  hideToggle: z.nullable(z.boolean()),
  status: z.nullable(OnlineExhibitionsStatusSchema),
  eyebrow: z.nullable(z.string()),
})

export const onlineExhibitionComplexFields = groq`
  "artists": artists[]->{fullName},
  heroMedia {
    ${mediaBuilder}
  },
  cardViewMedia {
    ${mediaBuilder}
  },
`

export const OnlineExhibitionComplexFieldsSchema = z.object({
  artists: z.nullable(z.array(z.object({ fullName: z.string() }))),
  heroMedia: z.nullable(MediaBuilderSchema),
  cardViewMedia: z.nullable(MediaBuilderSchema),
})

export const onlineExhibitionPageContent = groq`
  _type == 'onlineExhibitionPage' => {
    ${onlineExhibitionSimpleFields}
    ${onlineExhibitionComplexFields}
  },
`

export const OnlineExhibitionPageContentSchema = OnlineExhibitionSimpleFieldsSchema.merge(
  OnlineExhibitionComplexFieldsSchema
)
