import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'
import { moleculeTitleFields } from './builders/moleculeTitleBuilder'

export const dzLandingFields = groq`
  "_type": "dzLanding",
  ${moleculeTitleFields}
  'props': {
    title,
    elements[] {
      _key,
      media { ${mediaBuilder} },
      link {
        type,
        "href": select(
          type == 'Internal Path' => internalPathLink.href,
          type == 'External Link' => link.href,
          type == 'Internal Reference' => internalLink.reference->slug.current,
        ),
        "blank": select(
          type == 'Internal Path' => internalPathLink.blank,
          type == 'External Link' => link.blank,
          type == 'Internal Reference' => false,
        ),
      },
      title
    }
  }
`

export const dzLandingProps = groq`
  _type == 'dzLanding' => {
    ${dzLandingFields}
  },
`

export const DzLandingPropsDataSchema = z.object({
  title: z.nullable(z.string()),
  elements: z.array(
    z.object({
      _key: z.string(),
      media: MediaBuilderSchema,
      link: z.object({
        type: z.string(),
        href: z.string(),
        blank: z.coerce.boolean(),
      }),
      title: z.string(),
    })
  ),
})

export type DzLandingPropsDataType = z.infer<typeof DzLandingPropsDataSchema>
