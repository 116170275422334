import { groq } from 'next-sanity'
import { z } from 'zod'

import { ArtworkContentSchema } from '@/sanity/queries/components/content/artworkContent'

import { ctaBuilder, CTABuilderSchema, LinkCTASchema, NoneCTASchema } from './builders/ctaBuilder'
import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'
import { artworkFilterFields, ArtworkFiltersSchema } from './displayFilters'
import { basicContent, BasicContentSchema } from './portableText/basicContent'
import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from './portableText/basicContentWithLinks'

export const dzCardFields = groq`
  "_type": "dzCard",
  'props': {
    "discriminator": "dzCard",
    title,
    eyebrow,
    primaryCTA {
      ${ctaBuilder}
    },
    secondaryCTA {
      ${ctaBuilder}
    },
    linkCTA {
      ${ctaBuilder}
    },
    mediaOverride {
      ${mediaBuilder}
    },
    "enableOverrides": coalesce(enableOverrides, false),
    bookVariation,
    primaryTitle,
    secondaryTitle,
    primarySubtitle,
    secondarySubtitle,
    descriptionOverride[] {
      ${basicContent}
    },
    additionalInformation[] {
      ${basicContent}
    },
    artistName,
    artworkTitle[] {
      ${basicContent}
    },
    artworkYear,
    medium,
    dimensions,
    framedDimensions,
    framed,
    price,
    currency,
    editionInformation,
    productPrice,
    taglineOverride[] {
    ${basicContentWithLinks}
    },
    detail1,
    _type == 'oneUp' => {
      artworkFilters{${artworkFilterFields}},
    },
    "customCardEnabled": coalesce(customCardEnabled, false),
    customCardEnabled == true => {
      "discriminator": "dzCustomCard",
      customCardTitle,
      customCardSubtitle,
      customCardDescription[] {
        ${basicContentWithLinks}
      },
      customCardEyebrow,
      customCardSecondaryTitle,
      customCardSecondarySubtitle,
      customCardMedia {
        ${mediaBuilder}
      },
      customCardCta {
        ${ctaBuilder}
      },
      customCardLinkCTA {
        ${ctaBuilder}
      },
    }
  }
`

export const dzCardProps = groq`
  _type == 'dzCard' => {
    ${dzCardFields}
  },
`
const BookVariationSchema = z.nullable(z.enum(['productCard', 'contentCard']))

// props: DzCardPropsDataSchema
export const DzCardPropsDataSchema = z.object({
  discriminator: z.literal('dzCard'),
  title: z.string().nullish(),
  primaryCTA: z.nullable(CTABuilderSchema),
  secondaryCTA: z.nullable(CTABuilderSchema),
  linkCTA: z.nullable(LinkCTASchema.or(NoneCTASchema)),
  mediaOverride: z.nullable(MediaBuilderSchema),
  enableOverrides: z.boolean(),
  bookVariation: BookVariationSchema,
  eyebrow: z.nullable(z.string()),
  primaryTitle: z.nullable(z.string()),
  secondaryTitle: z.nullable(z.string()),
  primarySubtitle: z.nullable(z.string()),
  secondarySubtitle: z.nullable(z.string()),
  descriptionOverride: z.nullable(z.array(BasicContentSchema)),
  artistName: z.nullable(z.string()),
  artworkTitle: z.nullable(z.array(BasicContentSchema)),
  artworkYear: z.nullable(z.string()),
  medium: z.nullable(ArtworkContentSchema.shape.medium),
  dimensions: z.nullable(ArtworkContentSchema.shape.dimensions),
  framedDimensions: z.nullable(ArtworkContentSchema.shape.framedDimensions),
  framed: z.nullable(ArtworkContentSchema.shape.framed),
  price: z.nullable(ArtworkContentSchema.shape.price),
  productPrice: z.nullable(z.number()),
  currency: z.nullable(ArtworkContentSchema.shape.currency),
  editionInformation: z.nullable(ArtworkContentSchema.shape.editionInformation),
  additionalInformation: z.nullable(z.array(BasicContentSchema)),
  artworkFilters: ArtworkFiltersSchema.nullish(),
  taglineOverride: z.nullable(z.array(BasicContentWithLinksSchema)),
  detail1: z.nullable(z.string()),
  customCardEnabled: z.literal(false),
})

// props: DzCustomCardSchema
export const DzCustomCardPropsSchema = DzCardPropsDataSchema.merge(
  z.object({
    discriminator: z.literal('dzCustomCard'),
    customCardEnabled: z.literal(true),
    enableOverrides: z.literal(false),
    customCardTitle: z.string(),
    customCardSubtitle: z.nullable(z.string()),
    customCardDescription: z.nullable(z.array(BasicContentWithLinksSchema)),
    customCardEyebrow: z.nullable(z.string()),
    customCardSecondaryTitle: z.nullable(z.string()),
    customCardSecondarySubtitle: z.nullable(z.string()),
    customCardMedia: z.nullable(MediaBuilderSchema),
    customCardCta: z.nullable(CTABuilderSchema),
    customCardLinkCTA: z.nullable(LinkCTASchema.or(NoneCTASchema)),
  })
)

export type DzCardPropsDataType = z.infer<typeof DzCardPropsDataSchema>
export type DzCustomCardPropsDataType = z.infer<typeof DzCustomCardPropsSchema>
