import { groq } from 'next-sanity'

import { dzCardFields } from './dzCardProps'
import { dzMediaFields } from './dzMediaProps'

export const oneUpFields = groq`
  type == 'dzCard' => {
    ${dzCardFields}
  },
  type == 'dzMedia' => {
    ${dzMediaFields}
  },
`
export const OneUpProps = groq`
  _type == 'oneUp' => {
   ${oneUpFields}
  },
`

/* OneUP returns us either dzCard or dzMedia. It's return type will be validated in pagebuilder by that cases. Keeping this commented zod schema as data shape explanation.
const oneUpDataSchema = z.discriminatedUnion('type', [
  DzCardPropsDataSchema.extend({type: z.literal('dzCard')}),
  DzMediaPropsDataSchema.extend({type: z.literal('dzMedia')}),
])
*/
