import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder, CTABuilderSchema } from '../builders/ctaBuilder'
import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'

export const podcastContent = groq`
  _type == 'podcast' => {
    _type,
    title,
    description[] {
      ${basicContent}
    },
    subtitle,
    itunesUrl,
    spotifyUrl,
    googlePlayUrl,
    stitcherURL,
    iHeartUrl,
    mp3Url,
    image {
      ${mediaBuilder}
    },
    transcript,
    dateSelection,
    comingSoonText,
    eyebrow,
    cta{
      ${ctaBuilder}
    },
  },
`

export const PodcastContentSchema = z.object({
  _type: z.literal('podcast'),
  title: z.string(),
  cta: z.nullable(CTABuilderSchema),
  subtitle: z.nullable(z.string()),
  eyebrow: z.nullable(z.string()),
  dateSelection: z.nullable(z.any()),
  comingSoonText: z.nullable(z.string()),
  description: z.nullable(z.array(BasicContentSchema)),
  itunesUrl: z.nullable(z.string().url()),
  spotifyUrl: z.nullable(z.string().url()),
  googlePlayUrl: z.nullable(z.string().url()),
  stitcherURL: z.nullable(z.string().url()),
  iHeartUrl: z.nullable(z.string().url()),
  mp3Url: z.nullable(z.string().url()),
  transcript: z.nullable(z.any()),
  image: MediaBuilderSchema.nullish(),
})

export type PodcastContentType = z.infer<typeof PodcastContentSchema>
