import { groq } from 'next-sanity'
import { z } from 'zod'

export const artistPageContent = groq`
  _type == 'artistPage' => {
    _type,
    title,
    "slug": slug.current,
    "artist": artist-> {
      biographyPicture {
        _type,
        title,
        description,
        alt,
        altText,
        asset,
      }
    }
  },
`

export const ArtistPageContentSchema = z.object({
  _type: z.literal('artistPage'),
  title: z.string(),
  slug: z.string(),
  artist: z.object({
    biographyPicture: z.nullable(
      z.object({
        _type: z.string(),
        title: z.string().nullable(),
        description: z.string().nullable(),
        alt: z.string().nullable(),
        altText: z.string().nullable(),
        asset: z.nullable(
          z.object({
            _ref: z.string(),
            _type: z.string(),
          })
        ),
      })
    ),
  }),
})

export type ArtistPageContentSchemaType = z.infer<typeof ArtistPageContentSchema>
