import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder, CTABuilderSchema } from './builders/ctaBuilder'

export const dzHeroFields = groq`
  "_type": "dzHero",
  'props': {
    title,
    headingOverride,
    subHeadingOverride,
    secondaryTitleOverride,
    descriptionOverride,
    imageOverride,
    enableOverrides,
    categoryOverride,
    ctaOptions {
      primaryCTA {
        ${ctaBuilder}
      },
      secondaryCTA {
        ${ctaBuilder}
      }
    }
  }
`

export const dzHeroProps = groq`
  _type == 'dzHero' => {
    ${dzHeroFields}
  },
`

export const DzHeroPropsDataSchema = z.object({
  title: z.string().nullish(),
  headingOverride: z.nullable(z.string()),
  subHeadingOverride: z.nullable(z.string()),
  secondaryTitleOverride: z.nullable(z.string()),
  descriptionOverride: z.nullable(z.string()),
  imageOverride: z.nullable(z.any()),
  enableOverrides: z.boolean().nullish(),
  categoryOverride: z.nullable(z.string()),
  ctaOptions: z
    .object({
      primaryCTA: z.nullable(CTABuilderSchema),
      secondaryCTA: z.nullable(CTABuilderSchema),
    })
    .nullish(),
})

export type DzHeroSchemaProps = z.infer<typeof DzHeroPropsDataSchema>
