import { groq } from 'next-sanity'
import { z } from 'zod'

import { genGroqNode } from '@/sanity/util/generateGroqNode'
import { joinQ } from '@/sanity/util/joinSimpleQueries'

import {
  moleculeTitleFields,
  MoleculeTitleFieldsSchema,
} from '../../components/builders/moleculeTitleBuilder'
import {
  componentTypesData,
  ComponentTypesDataSchema,
  type DataRecordTypesNames,
  getHeroContentWrapperFields,
  queryByContentType,
  wrappedContentPerMolecule,
} from '../../components/componentTypesData'
import { dzCardProps, DzCardPropsDataSchema } from '../../components/dzCardProps'
import {
  dzCarouselProps,
  DzCarouselPropsDataSchema,
  getDzCarouselFieldsSimplified,
} from '../../components/dzCarouselProps'
import {
  dzEditorialFields,
  dzEditorialProps,
  DzEditorialPropsDataSchema,
} from '../../components/dzEditorialProps'
import {
  dzGalleryHeroFields,
  dzGalleryHeroProps,
  DzGalleryHeroPropsDataSchema,
} from '../../components/dzGalleryHeroProps'
import { dzHeroFields, dzHeroProps, DzHeroPropsDataSchema } from '../../components/dzHeroProps'
import {
  dzImageMosaicFields,
  dzImageMosaicProps,
  DzImageMosaicPropsDataSchema,
} from '../../components/dzImageMosaicProps'
import {
  dzInterstitialFields,
  dzInterstitialProps,
  DzInterstitialPropsDataSchema,
} from '../../components/dzInterstitialProps'
import {
  dzLandingFields,
  dzLandingProps,
  DzLandingPropsDataSchema,
} from '../../components/dzLandingProps'
import { dzMediaFields, dzMediaProps, DzMediaPropsDataSchema } from '../../components/dzMediaProps'
import { dzSplitFields, dzSplitProps, DzSplitPropsDataSchema } from '../../components/dzSplitProps'
import {
  DzGridMoleculePropsDataSchema,
  getGridFieldsSimplified,
  gridMoleculeProps,
} from '../../components/gridMoleculeProps'
import { oneUpFields, OneUpProps } from '../../components/oneupProps'

export const carouselDataTypes = groq`
  _type,
 'dataTypes':array::join(array::compact(array::unique(
    dzCarousel[]{
    't':array::join(content[]->{_type}._type, ',')
  }.t)),','),
`

export const gridDataTypes = groq`
  _type,
 'dataTypes':array::join(array::compact(array::unique(
    grid[]{
    't':array::join(content[]->{_type}._type, ',')
  }.t)),','),
`

export const commonDataTypes = groq`
  _type,
 'dataTypes':array::join(content[]->{_type}._type, ','),
`

export const tabDataTypes = groq`
  _type,
 'dataTypes': array::join(content[]{_type}._type, ','),
`

export const heroDataTypes = groq`
  _type,
  'dataTypes':array::join(array::compact(array::unique(content[]{'t': array::join(array::compact(array::unique(content[]->{_type}._type)),',')}.t)),','),
`

export enum MoleculeTypesWithContent {
  dzCarousel = 'dzCarousel',
  dzSplit = 'dzSplit',
  oneUp = 'oneUp',
  dzHero = 'dzHero',
  grid = 'grid',
  dzInterstitial = 'dzInterstitial',
  dzMedia = 'dzMedia',
  dzEditorial = 'dzEditorial',
  dzGalleryHero = 'dzGalleryHero',
  dzImageMosaic = 'dzImageMosaic',
  dzLanding = 'dzLanding',
}
type MoleculeTypesNames = keyof typeof MoleculeTypesWithContent

type QueryBuilderConf = {
  hideMoleculeTitle?: boolean
  wrapper?: (key: string, query: string) => string
  aliases?: Record<string, string>
}

const DEFAULT_CONFIG: QueryBuilderConf = {
  hideMoleculeTitle: false,
  aliases: {},
}

const processPBNode = (node: StructureBase[]) => {
  return node.reduce((prev: any, curr, i) => {
    const { _type, dataTypes } = curr
    const types = dataTypes?.split(',') ?? []
    if (!_type) return prev
    prev[_type] = [...new Set([...(prev?.[_type] ?? []), ...types])]
    if (i === node.length - 1) {
      return Object.entries(prev).map(([key, val]) => ({
        _type: key,
        dataTypes: (val as string[]).join(',') || null,
      }))
    }
    return prev
  }, {})
}

type PageBuilderStructure = StructureBase | StructureBase[] | null
type NestedPageBuilderStructure = { content: PageBuilderStructure }

export const mergeStructure = (
  structure: Record<string, PageBuilderStructure | NestedPageBuilderStructure[]>
) => {
  if (!structure) return structure
  return Object.entries(structure).reduce((prev: any, [key, value]) => {
    if (Array.isArray(value)) {
      // Support one level of nested page builder
      if ('content' in value[0]) {
        const node = value.reduce((prev: any, curr) => {
          const content = (curr as NestedPageBuilderStructure).content as StructureBase[]
          const processedPB = processPBNode(content)
          return [...prev, { content: processedPB }]
        }, [])
        return { ...prev, [key]: node }
      }

      // Process page builder as usual
      const processedPB = processPBNode(value as StructureBase[])
      prev[key] = processedPB
      return prev
    }
    prev[key] = value
    return prev
  }, {})
}

type QueryByMoleculeType = Record<
  MoleculeTypesNames,
  (content: string, isPageBuilderArray: boolean, innerContent: string) => string
>

export const queryByMoleculeType: QueryByMoleculeType = {
  dzSplit: (content, isPageBuilderArray) => {
    return isPageBuilderArray
      ? genGroqNode(" _type == 'dzSplit' => ", joinQ(dzSplitFields, content))
      : joinQ(dzSplitFields, content)
  },
  oneUp: (content, isPageBuilderArray) => {
    return isPageBuilderArray
      ? genGroqNode(" _type == 'oneUp' => ", joinQ(oneUpFields, content))
      : joinQ(oneUpFields, content)
  },
  dzCarousel: (content, isPageBuilderArray) => {
    const dzCarouselFields = getDzCarouselFieldsSimplified(content)
    return isPageBuilderArray
      ? genGroqNode(" _type == 'dzCarousel' => ", dzCarouselFields)
      : dzCarouselFields
  },
  /*
   * Hero content works different as it has an intermediate wrapper in the CMS so the structure of the node is different
   * here innerContent represents the wrapper structure
   */
  dzHero: (_, isPageBuilderArray, innerContent) => {
    const heroContent = getHeroContentWrapperFields(innerContent)
    const wrapperContent = isPageBuilderArray
      ? genGroqNode("_type == 'dzHero' =>", heroContent)
      : heroContent
    return isPageBuilderArray
      ? genGroqNode(" _type == 'dzHero' => ", joinQ(dzHeroFields, wrapperContent))
      : joinQ(dzHeroFields, wrapperContent)
  },
  grid: (content, isPageBuilderArray) => {
    const gridFields = getGridFieldsSimplified(content)
    return isPageBuilderArray ? genGroqNode(" _type == 'grid' => ", gridFields) : gridFields
  },
  dzInterstitial: (_, isPageBuilderArray) =>
    isPageBuilderArray
      ? genGroqNode(" _type == 'dzInterstitial' => ", dzInterstitialFields)
      : dzInterstitialFields,
  dzMedia: (_, isPageBuilderArray) =>
    isPageBuilderArray ? genGroqNode(" _type == 'dzMedia' => ", dzMediaFields) : dzMediaFields,
  dzEditorial: (_, isPageBuilderArray) =>
    isPageBuilderArray
      ? genGroqNode(" _type == 'dzEditorial' => ", dzEditorialFields)
      : dzEditorialFields,
  dzGalleryHero: (_, isPageBuilderArray) =>
    isPageBuilderArray
      ? genGroqNode(" _type == 'dzGalleryHero' => ", dzGalleryHeroFields)
      : dzGalleryHeroFields,
  dzImageMosaic: (_, isPageBuilderArray) =>
    isPageBuilderArray
      ? genGroqNode(" _type == 'dzImageMosaic' => ", dzImageMosaicFields)
      : dzImageMosaicFields,
  dzLanding: (_, isPageBuilderArray) =>
    isPageBuilderArray
      ? genGroqNode(" _type == 'dzLanding' => ", dzLandingFields)
      : dzLandingFields,
}

export type StructureBase = {
  dataTypes?: string
  _type?: MoleculeTypesWithContent
}

type GetQueryForStructureSection = (params: {
  section: StructureBase
  config: QueryBuilderConf
  isPageBuilderArray?: boolean
}) => string

const getQueryForStructureSection: GetQueryForStructureSection = ({
  section,
  config,
  isPageBuilderArray = false,
}) => {
  const { _type, dataTypes } = section
  if (!_type || !MoleculeTypesWithContent[_type]) return ''
  const individualTypes = dataTypes ? dataTypes.split(',') : []
  const moleculeTitle =
    !config.hideMoleculeTitle &&
    ['dzHero', 'dzSplit', 'dzCarousel', 'grid', 'oneUp'].includes(_type)
      ? moleculeTitleFields
      : ''
  const queryForDataTypes = individualTypes.reduce((prev, curr) => {
    const query = queryByContentType[curr as DataRecordTypesNames]
    return joinQ(prev, query)
  }, '')
  const contentNode = genGroqNode('content[]->', queryForDataTypes)
  const queryForMoleculeType = queryByMoleculeType[_type]?.(
    contentNode,
    isPageBuilderArray,
    queryForDataTypes
  )
  return joinQ(queryForMoleculeType, moleculeTitle)
}

type GetQueryBasedOnStructureNode = (params: {
  node: StructureBase | StructureBase[] | null
  config: QueryBuilderConf
}) => string

export const getQueryBasedOnStructureNode: GetQueryBasedOnStructureNode = ({ node, config }) => {
  if (!node) return ''
  if (Array.isArray(node)) {
    return node.reduce(
      (previous, current) =>
        joinQ(
          previous,
          getQueryForStructureSection({ section: current, config, isPageBuilderArray: true })
        ),
      ''
    )
  }
  return getQueryForStructureSection({ section: node, config })
}

type GetQueryBasedOnStructure = (params: {
  structure: Record<string, StructureBase | StructureBase[] | null>
  config?: QueryBuilderConf
}) => string

export const getQueryBasedOnStructure: GetQueryBasedOnStructure = ({
  structure,
  config = DEFAULT_CONFIG,
}) => {
  if (!structure) return ''
  return Object.entries(structure).reduce((prev, [key, value]) => {
    const keyAlias = config.aliases?.[key]
    const isArrayNode = Array.isArray(value)
    const wrapperFunction = config.wrapper ?? ((_: string, query: string) => query)

    // Support one level of nested page builder
    if (isArrayNode && 'content' in value[0]) {
      const types = value
        // TODO Remove any
        .map(({ content }: any) => {
          const key = 'content'
          const groqNodeQuery = wrapperFunction(
            key,
            getQueryBasedOnStructureNode({ node: content, config })
          )
          return groqNodeQuery
        })
        .join(',')

      const res = genGroqNode(
        `${keyAlias ?? key}${isArrayNode ? '[]' : ''}`,
        genGroqNode(`content[]`, types)
      )
      return joinQ(prev, res)
    }

    // Process page builder as usual
    const groqNodeQuery = genGroqNode(
      `${keyAlias ?? key}${isArrayNode ? '[]' : ''}`,
      wrapperFunction(key, getQueryBasedOnStructureNode({ node: value, config }))
    )
    return joinQ(prev, groqNodeQuery)
  }, '')
}

export const pageBuilderDataTypes = groq`
  _type == 'dzCarousel' => {
    ${carouselDataTypes}
  },
  _type == 'dzSplit' => {
    reverse,
    ${commonDataTypes}
  },
  _type == 'oneUp' => {
    ${commonDataTypes}
  },
  _type == 'dzHero' =>  {
    ${heroDataTypes}
  },
  _type == 'grid' => {
    ${gridDataTypes}
  },
  _type == 'dzInterstitial' => {
    _type
  },
  _type == 'dzMedia' => {
    _type
  },
  _type == 'dzEditorial' => {
    _type
  },
  _type == "dzTab" => {
    ${tabDataTypes}
  },
  _type == 'dzLanding' => {
    _type
  },
  _type == 'dzGalleryHero' => {
    _type
  },
  _type == 'dzImageMosaic' => {
    _type
  }
`

export const moleculesProps = groq`
  ${gridMoleculeProps}
  ${dzCardProps}
  ${dzCarouselProps}
  ${dzEditorialProps}
  ${dzHeroProps}
  ${dzInterstitialProps}
  ${dzSplitProps}
  ${dzMediaProps}
  ${OneUpProps}
  ${dzGalleryHeroProps}
  ${dzImageMosaicProps}
  ${dzLandingProps}
  _type in ['dzHero', 'dzSplit', 'dzCarousel', 'grid', 'oneUp'] => {
    ${moleculeTitleFields}
  },
`

export const pageBuilderComponentsData = groq`
  title,
  ${moleculesProps}
  ${componentTypesData}
  ${wrappedContentPerMolecule}
`

const PageBuilderBase = z.object({
  title: z.string().nullish(),
  content: ComponentTypesDataSchema,
})
const PageBuilderNoContent = z.object({
  title: z.string().nullish(),
  content: z.undefined().nullable(),
})

// Page builder components
export const PageBuilderDzCard = PageBuilderBase.extend({ _type: z.literal('dzCard') }).merge(
  z.object({ props: DzCardPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema })
)
export const PageBuilderDzCarousel = PageBuilderNoContent.extend({
  _type: z.literal('dzCarousel'),
}).merge(z.object({ props: DzCarouselPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema }))
export const PageBuilderDzHero = PageBuilderBase.extend({ _type: z.literal('dzHero') }).merge(
  z.object({ props: DzHeroPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema })
)
export const PageBuilderDzSplit = PageBuilderBase.extend({ _type: z.literal('dzSplit') }).merge(
  z.object({ props: DzSplitPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema })
)
export const PageBuilderDzMedia = PageBuilderBase.extend({ _type: z.literal('dzMedia') }).merge(
  z.object({ props: DzMediaPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema })
)
export const PageBuilderGrid = PageBuilderNoContent.extend({ _type: z.literal('grid') }).merge(
  z.object({ props: DzGridMoleculePropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema })
)
export const PageBuilderDzLanding = PageBuilderNoContent.extend({
  _type: z.literal('dzLanding'),
}).merge(z.object({ props: DzLandingPropsDataSchema, dzMoleculeTitle: MoleculeTitleFieldsSchema }))

export const PageBuilderDzEditorial = PageBuilderNoContent.extend({
  _type: z.literal('dzEditorial'),
}).merge(z.object({ props: DzEditorialPropsDataSchema }))
export const PageBuilderDzInterstitial = PageBuilderNoContent.extend({
  _type: z.literal('dzInterstitial'),
}).merge(z.object({ props: DzInterstitialPropsDataSchema }))
export const PageBuilderDzGalleryHero = PageBuilderNoContent.extend({
  _type: z.literal('dzGalleryHero'),
}).merge(z.object({ props: DzGalleryHeroPropsDataSchema }))
export const PageBuilderDzImageMosaic = PageBuilderNoContent.extend({
  _type: z.literal('dzImageMosaic'),
}).merge(z.object({ props: DzImageMosaicPropsDataSchema }))

export const PageBuilderComponentsDataSchema = z.discriminatedUnion('_type', [
  PageBuilderDzCard,
  PageBuilderDzCarousel,
  PageBuilderDzHero,
  PageBuilderDzSplit,
  PageBuilderDzMedia,
  PageBuilderGrid,
  PageBuilderDzEditorial,
  PageBuilderDzInterstitial,
  PageBuilderDzLanding,
  PageBuilderDzGalleryHero,
  PageBuilderDzImageMosaic,
])

export type PageBuilderDzGridSchemaType = z.infer<typeof PageBuilderGrid>
export type PageBuilderDzInterstitialSchemaType = z.infer<typeof PageBuilderDzInterstitial>
export type PageBuilderComponentsDataSchemaType = z.infer<typeof PageBuilderComponentsDataSchema>
