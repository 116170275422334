import { groq } from 'next-sanity'
import { z } from 'zod'

import { FranchiseListTypes } from '../../page/pageCommonQueries/pageSimpleFields'
import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'
import { SanitySlugSchema } from '../validationPrimitives'

export const exceptionalWorksSimpleFields = groq`
  _id,
  _type,
  slug,
  title,
  subtitle,
  startDate,
  endDate,
  "description": summary[] {
    ${basicContent}
  },
  franchiseBranding,
  eyebrow,
  status,
  displayDate,
  hideToggle,
`

const ExceptionalWorksStatusSchema = z.enum(['comingSoon', 'open', 'close'])

export const ExceptionalWorksSimpleFieldsSchema = z.object({
  _id: z.string(),
  _type: z.literal('exceptionalWork'),
  slug: SanitySlugSchema,
  title: z.string(),
  subtitle: z.nullable(z.string()),
  startDate: z.string(),
  endDate: z.string(),
  description: z.nullable(z.array(BasicContentSchema)),
  franchiseBranding: z.nullable(FranchiseListTypes),
  eyebrow: z.nullable(z.string()),
  status: z.nullable(ExceptionalWorksStatusSchema),
  displayDate: z.nullable(z.string()),
  hideToggle: z.nullable(z.boolean()),
})

export const exceptionalWorksComplexFields = groq`
  "artists": artists[]->{fullName},
  heroMedia {
    ${mediaBuilder}
  },
  cardViewMedia {
    ${mediaBuilder}
  },
`

const ExceptionalWorksComplexFieldsSchema = z.object({
  artists: z.nullable(z.array(z.object({ fullName: z.string() }))),
  heroMedia: z.nullable(MediaBuilderSchema),
  cardViewMedia: z.nullable(MediaBuilderSchema),
})

export const exceptionalWorksPageContent = groq`
  _type == 'exceptionalWork' => {
    ${exceptionalWorksSimpleFields}
    ${exceptionalWorksComplexFields}
  },
`

export const ExceptionalWorksPageContentSchema = ExceptionalWorksSimpleFieldsSchema.merge(
  ExceptionalWorksComplexFieldsSchema
)
