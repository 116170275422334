import { groq } from 'next-sanity'
import { z } from 'zod'

import {
  mediaBuilder,
  MediaBuilderCustomVideoSchema,
  MediaBuilderImageSchema,
  MediaBuilderVideoRecordSchema,
} from '@/sanity/queries/components/builders/mediaBuilder'

import { editorialContent, EditorialContentSchema } from './editorialContent'
import { basicFields } from './primitives'

export const articleRTEContent = groq`
  ${basicFields}
  _type == 'block' => {
    "type": _type,
    ${editorialContent}
  },
  _type == 'bodyImage' => media {
    ${mediaBuilder}
  },
`

const BodyImageSchema = z.object({
  _key: z.string(),
  _type: z.literal('bodyImage'),
})

export const ArticleRTEContentSchema = z.discriminatedUnion('type', [
  EditorialContentSchema.merge(
    z.object({
      type: z.literal('block'),
    })
  ),
  MediaBuilderImageSchema.merge(BodyImageSchema),
  MediaBuilderCustomVideoSchema.merge(BodyImageSchema),
  MediaBuilderVideoRecordSchema.merge(BodyImageSchema),
])
