import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from '../../components/builders/mediaBuilder'
import { SanitySlugSchema } from '../../components/validationPrimitives'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'

export const ecommCollectSimpleFields = groq`
  _type,
  title,
  slug,
  description[] {
    ${basicContent}
  },
  cardViewMedia {
    ${mediaBuilder}
  },
`

export const ecommCollectCardContent = groq`
  _type == 'collectionPage' => {
    ${ecommCollectSimpleFields}
  },
`

export const EcommCollectCardContentSchema = z.object({
  _type: z.literal('collectionPage'),
  title: z.string(),
  description: z.nullable(z.array(BasicContentSchema)),
  cardViewMedia: z.nullable(MediaBuilderSchema),
  slug: SanitySlugSchema,
})

export type EcommCollectCardContentType = z.infer<typeof EcommCollectCardContentSchema>
