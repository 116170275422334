import { groq } from 'next-sanity'
import { z } from 'zod'

export const bookFilterFields = groq`
        "filterType": "book",
        bookTitle,
        bookSubtitle,
        bookPrice,
        primaryCTA,
        bookTagline,
        bookPublisher,
        bookMarketingDescription,
        secondaryCTA,
`

export const artworkFilterFields = groq` 
        "filterType": "artwork",
        artistName,
        artworkCTA,
        artworkDateSelection,
        artworkDimensions,
        artworkEdition,
        artworkFramed,
        artworkFramedDimensions,
        artworkMedium,
        artworkPrice,
        artworkTitle,
`

export const BookFiltersSchema = z.object({
  filterType: z.literal('book'),
  bookTitle: z.nullable(z.boolean()),
  bookSubtitle: z.nullable(z.boolean()),
  bookPrice: z.nullable(z.boolean()),
  primaryCTA: z.nullable(z.boolean()),
  bookTagline: z.nullable(z.boolean()),
  bookPublisher: z.nullable(z.boolean()),
  bookMarketingDescription: z.nullable(z.boolean()),
  secondaryCTA: z.nullable(z.boolean()),
})

export const ArtworkFiltersSchema = z.object({
  filterType: z.literal('artwork'),
  artistName: z.nullable(z.boolean()),
  artworkCTA: z.nullable(z.boolean()),
  artworkDateSelection: z.nullable(z.boolean()),
  artworkDimensions: z.nullable(z.boolean()),
  artworkEdition: z.nullable(z.boolean()),
  artworkFramed: z.nullable(z.boolean()),
  artworkFramedDimensions: z.nullable(z.boolean()),
  artworkMedium: z.nullable(z.boolean()),
  artworkPrice: z.nullable(z.boolean()),
  artworkTitle: z.nullable(z.boolean()),
})

export const DisplayFiltersSchema = z.object({
  artworkFilters: ArtworkFiltersSchema.nullish(),
  bookFilters: BookFiltersSchema.nullish(),
})

export type BookFiltersType = z.infer<typeof BookFiltersSchema>
export type ArtworkFiltersType = z.infer<typeof ArtworkFiltersSchema>
