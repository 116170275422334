import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'
import { basicContent, BasicContentSchema } from './portableText/basicContent'
import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from './portableText/basicContentWithLinks'
import { editorialContent, EditorialContentSchema } from './portableText/editorialContent'

const EditorialTypes = z.enum(['quote', 'simple', 'leftBlock', 'complex', 'centered'])
const EditorialTextOverridesContentTypes = z.enum(['paragraph', 'quote'])
const EditorialTextOverridesTypes = z.enum([
  'editorialTextOverridesSimpleItems',
  'editorialTextOverridesCenteredItems',
  'editorialTextOverridesComplex',
  'editorialTextOverridesLeftBlockItems',
])

const dzEditorialTextOverridesContent = groq`
  _key,
  _type,
  textType,
  textType == '${EditorialTextOverridesContentTypes.enum.paragraph}' => {
    text[] {
      ${editorialContent}
    }
  },
  textType == '${EditorialTextOverridesContentTypes.enum.quote}' => {
    pullQuoteTitle[] {
      ${basicContentWithLinks}
    },
    pullQuoteAttribution[] {
      ${basicContentWithLinks}
    },
  }
`

export const dzEditorialFields = groq`
  "_type": "dzEditorial",
  'props': {
    title,
    editorialType == '${EditorialTypes.enum.quote}' => {
      editorialType,
      quoteTitle[] {
        ${basicContentWithLinks}
      },
      quoteFootNote[] {
        ${basicContentWithLinks}
      },
    },
    editorialType == '${EditorialTypes.enum.simple}' => {
      editorialType,
      editorialTextOverridesSimple[] {
        ${basicContent}
      },
    },
    editorialType == '${EditorialTypes.enum.centered}' => {
      editorialType,
      editorialTextOverridesCentered[] {
        ${dzEditorialTextOverridesContent}
      },
    },
    editorialType == '${EditorialTypes.enum.leftBlock}' => {
      editorialType,
      editorialTextOverridesLeftBlock[] {
        ${dzEditorialTextOverridesContent}
      },
    },
    editorialType == '${EditorialTypes.enum.complex}' => {
      editorialType,
      editorialTextOverrides[] {
        ${dzEditorialTextOverridesContent}
      },
      imageOverride[] {
        title,
        media {
          ${mediaBuilder}
        }
      },
      reverse
    }
  }
`

export const dzEditorialProps = groq`
  _type == 'dzEditorial' => {
    ${dzEditorialFields}
  },
`

const DzEditorialTextOverridesContentBase = z.object({
  _key: z.string(),
  _type: EditorialTextOverridesTypes,
})

const DzEditorialTextOverridesContentSchema = z.discriminatedUnion('textType', [
  DzEditorialTextOverridesContentBase.extend({
    textType: z.literal(EditorialTextOverridesContentTypes.enum.paragraph),
    text: z.array(EditorialContentSchema),
  }),
  DzEditorialTextOverridesContentBase.extend({
    textType: z.literal(EditorialTextOverridesContentTypes.enum.quote),
    pullQuoteTitle: z.array(BasicContentWithLinksSchema),
    pullQuoteAttribution: z.array(BasicContentWithLinksSchema).nullable(),
  }),
])

const DzEditorialTextOverridesSchema = z.nullable(z.array(DzEditorialTextOverridesContentSchema))

const DzEditorialPropsDataBase = z.object({
  title: z.nullable(z.string()),
})

// TODO: remove DzEditorialSchemaProps from src/sanity/types.ts and use DzEditorialPropsDataSchema instead
export const DzEditorialPropsDataSchema = z.discriminatedUnion('editorialType', [
  DzEditorialPropsDataBase.extend({
    editorialType: z.literal(EditorialTypes.enum.quote),
    quoteTitle: z.array(BasicContentWithLinksSchema),
    quoteFootNote: z.nullable(z.array(BasicContentWithLinksSchema)),
  }),
  DzEditorialPropsDataBase.extend({
    editorialType: z.literal(EditorialTypes.enum.simple),
    editorialTextOverridesSimple: z.nullable(z.array(BasicContentSchema)),
  }),
  DzEditorialPropsDataBase.extend({
    editorialType: z.literal(EditorialTypes.enum.centered),
    editorialTextOverridesCentered: DzEditorialTextOverridesSchema,
  }),
  DzEditorialPropsDataBase.extend({
    editorialType: z.literal(EditorialTypes.enum.leftBlock),
    editorialTextOverridesLeftBlock: DzEditorialTextOverridesSchema,
  }),
  DzEditorialPropsDataBase.extend({
    editorialType: z.literal(EditorialTypes.enum.complex),
    editorialTextOverrides: DzEditorialTextOverridesSchema,
    imageOverride: z.nullable(
      z.array(
        z.object({
          title: z.string(),
          media: MediaBuilderSchema,
        })
      )
    ),
    reverse: z.boolean(),
  }),
])

export type DzEditorialPropsDataType = z.infer<typeof DzEditorialPropsDataSchema>
