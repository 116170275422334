import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder, LinkContentCTASchema, LinkCTASchema, NoneCTASchema } from './ctaBuilder'

export const moleculeTitleFields = groq`
  dzMoleculeTitle {
    enabled,
    title,
    subtitle,
    cta {
      ${ctaBuilder}
    }
  },
`

export const CTASchema = z.discriminatedUnion('action', [
  LinkCTASchema,
  LinkContentCTASchema,
  NoneCTASchema,
])

export const MoleculeTitleBase = z.object({
  subtitle: z.nullable(z.string()),
  cta: z.nullable(CTASchema),
})

// remove "nullability" after migration to make "enabled" false by default for old records will be done
export const MoleculeTitleFieldsSchema = z.nullable(
  z.discriminatedUnion('enabled', [
    MoleculeTitleBase.extend({ enabled: z.literal(false) }).merge(
      z.object({ title: z.nullable(z.string()) })
    ),
    MoleculeTitleBase.extend({ enabled: z.literal(true) }).merge(z.object({ title: z.string() })),
  ])
)

export type MoleculeTitleFieldsSchemaType = z.infer<typeof MoleculeTitleFieldsSchema>
