import { groq } from 'next-sanity'
import { z } from 'zod'

import { SanitySlugSchema } from '../../components/validationPrimitives'
import { mediaBuilder, MediaBuilderSchema } from '../builders/mediaBuilder'
import { basicContent, BasicContentSchema } from '../portableText/basicContent'

export const ecommArtistBooksCardContentFields = groq`
  _type,
  title,
  slug,
  cardViewMedia {
    ${mediaBuilder}
  },
  description[] {
    ${basicContent}
  },
`

export const ecommArtistBooksCardContent = groq`
  _type == 'artistECommPage' => {
    ${ecommArtistBooksCardContentFields}
  },
`

export const EcommArtistBookCardContentSchema = z.object({
  _type: z.literal('artistECommPage'),
  title: z.string(),
  slug: z.nullable(SanitySlugSchema),
  description: z.nullable(z.array(BasicContentSchema)),
  cardViewMedia: z.nullable(MediaBuilderSchema),
})

export type EcommArtistBooksCardContentType = z.infer<typeof EcommArtistBookCardContentSchema>
