import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'

export const dzImageMosaicFields = groq`
  "_type": "dzImageMosaic",
  'props': {
    title,
    elements[] {
      mediaControls {
        "offset": coalesce(offset, '1col'),
        "width": coalesce(width, '10col')
      },
      media { ${mediaBuilder} }
    }
  }
`

export const dzImageMosaicProps = groq`
  _type == 'dzImageMosaic' => {
    ${dzImageMosaicFields}
  },
`

export const ColsSchema = z.enum([
  '1col',
  '2col',
  '3col',
  '4col',
  '5col',
  '6col',
  '7col',
  '8col',
  '9col',
  '10col',
  '11col',
  '12col',
])

export type Cols = z.infer<typeof ColsSchema>

export const DzImageMosaicPropsDataSchema = z.object({
  title: z.string(),
  elements: z.array(
    z.object({
      mediaControls: z.object({
        offset: ColsSchema,
        width: ColsSchema,
      }),
      media: MediaBuilderSchema,
    })
  ),
})

export type DzImageMosaicPropsDataType = z.infer<typeof DzImageMosaicPropsDataSchema>
