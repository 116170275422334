import { groq } from 'next-sanity'
import { z } from 'zod'

import { componentTypesData, ComponentTypesDataSchema } from './componentTypesData'
import { artworkFilterFields, bookFilterFields, DisplayFiltersSchema } from './displayFilters'
import { dzCardProps, DzCardPropsDataSchema, DzCustomCardPropsSchema } from './dzCardProps'
import { dzMediaProps, DzMediaPropsDataSchema } from './dzMediaProps'

// Must follow GridMoleculeTypeProps
export const dzGridFields = groq`
  "_type": "grid",
  'props': {
    title,
    "wrap": coalesce(wrap, false),
    itemsPerRow,
    displayNumberOfItems,
    displayGridSlider,
    artworkFilters{${artworkFilterFields}},
    bookFilters{${bookFilterFields}},
    grid[]{
      ${componentTypesData}
      ${dzMediaProps}
      ${dzCardProps}
    }
  },
`
export const gridMoleculeProps = groq`
  _type == 'grid' => {
    ${dzGridFields}
  },
`

export const getGridFieldsSimplified = (content: string) => groq`
  "_type": "grid",
  'props': {
    title,
    "wrap": coalesce(wrap, false),
    itemsPerRow,
    displayNumberOfItems,
    displayGridSlider,
    artworkFilters{${artworkFilterFields}},
    bookFilters{${bookFilterFields}},
    grid[]{
      ${dzMediaProps}
      ${dzCardProps}
      ${content}
    }
  },
`

const GridMoleculeItemTypeSchema = z.enum(['dzCard', 'dzMedia'])
export type GridMoleculeItemType = z.infer<typeof GridMoleculeItemTypeSchema>

export const DzGridMoleculePropsDataSchema = z
  .object({
    title: z.string().nullish(),
    wrap: z.boolean(),
    itemsPerRow: z.number().int().min(1).max(4).nullish(),
    displayNumberOfItems: z.nullable(z.boolean()),
    displayGridSlider: z.nullable(z.boolean()),
    grid: z.nullable(
      z.array(
        z.object({
          _type: GridMoleculeItemTypeSchema,
          content: z.nullable(ComponentTypesDataSchema),
          props: z.discriminatedUnion('discriminator', [
            DzCardPropsDataSchema,
            DzCustomCardPropsSchema,
            DzMediaPropsDataSchema,
          ]),
        })
      )
    ),
  })
  .merge(DisplayFiltersSchema)

export type DzGridMoleculePropsData = z.infer<typeof DzGridMoleculePropsDataSchema>
