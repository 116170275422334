import { groq } from 'next-sanity'
import { z } from 'zod'

import { mediaBuilder, MediaBuilderSchema } from './builders/mediaBuilder'

// Must follow DzMediaSchemaProps
export const dzMediaFields = groq`
  "_type": "dzMedia",
  'props': {
    "discriminator": "dzMedia",
    title,
    media {
      ${mediaBuilder}
    }
  }
`

export const dzMediaProps = groq`
  _type == 'dzMedia' => {
    ${dzMediaFields}
  },
`
export const DzMediaPropsDataSchema = z.object({
  discriminator: z.literal('dzMedia'),
  title: z.nullable(z.string()),
  media: z.nullable(MediaBuilderSchema),
})
